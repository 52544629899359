<template>
  <div class="contents">
    <div class="w-full px-2">
      <vue-data
        :value="item.content.images && item.content.images[`image_${item.key}`] ? item.content.images[`image_${item.key}`] : false"
        :edit="true"
        class="mb-2"
        type="file"
        accept="image/png, image/jpeg, image/gif"
        label="Image"
        @update="syncImage($event, `image_${item.key}`)"
        @discard="removeImage(`image_${item.key}`)"
      />
    </div>
    <div class="w-full px-2">
      <vue-input-group
        v-model="item.content.title"
        prop="title"
        name="title"
        label="Title"
      />
    </div>
    <div class="w-full px-2">
      <vue-input-group
        v-model="item.content[`image_${item.key}_alt`]"
        :prop="`image_${item.key}_alt`"
        :name="`image_${item.key}_alt`"
        label="Image Alt Text"
      />
    </div>
  </div>
</template>
<script>
import HasImageUpload from '../../../mixins/HasImageUpload'

export default {
  mixins: [
    HasImageUpload
  ],

  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
